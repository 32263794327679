<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>
 
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->
              
            </div>
          </template>

          <template v-slot:end>
            
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>
       
        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true" showGridlines
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b> Manage {{ head[id - 1] }}</b></h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>

          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
          <Column header="Status" style="min-width: 3rem;text-align: center;" :field="columns[2]">
            <template #body="{ data }">
            <span v-if="data.status=='Active' ||data.status=='active'"><badge :value=data.status severity="success"></badge></span>
            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge :value=data.status severity="warning"></badge></span>
            <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge :value=data.status severity="danger"></badge></span>
            <span v-else>-</span>
            </template>
          </Column>
          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>

          <Column header="ID" style="min-width: 3rem" :field="columns[10]">
            <template #body="{ data }">
              {{ data.id }}
            </template>
          </Column>
          <Column header="Name" style="min-width: 5rem" :field="columns[0]">
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>
          <!-- <Column header="Type">
            <template #body="{ data }">
              {{ data.type }}
            </template>
          </Column> -->
          <Column header="Desciption" style="min-width: 5rem" :field="columns[1]">
            <template #body="{ data }">
              {{ data.description }}
            </template>
          </Column>
          
<Column header="Image" style="min-width: 5rem">
            <template #body="slotProps">
            
              <img
                :src="slotProps.data.imageurl"
                :alt="slotProps.data.imageurl"
                class="shadow-2"
                width="50"
                v-if="slotProps.data.imageurl"
                
                
              />
              
            </template>
          </Column>
          <Column header="File Path" style="width: 4rem" :field="columns[9]">
            <template #body="{ data }">
              <a v-if="data.weblink" :href="data.weblink" target="_blank">View</a>
            </template>
          </Column>
          
          <Column header="Action" headerStyle="min-width:5rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px', width: '450px' }"
          header="Scheme Details"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field">
                <!-- <img
            :src="'images/product/shoes.jpg'"
            :alt="product.image"
            width="150"
            class="mt-0 mx-auto mb-5 block shadow-2"
          /> -->
              </div>
              <div class="field">
                <label for="name">Name</label>
                <InputText
                  id="name"
                  v-model.trim="product.name"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.name }"
                />
                <!-- <small class="p-invalid" v-if="submitted && !product.name"
                  >Name is required.</small
                > -->
                
              </div>
              <div class="field">
                <label for="description">Description</label>
                <Textarea
                  id="description"
                  v-model.trim="product.description"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.description }"
                />
              </div>
              <div class="field">
                <label for="link">Web Link</label>
                <InputText
                  id="link"
                  v-model.trim="product.weblink"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.weblink }"
                />
              </div>

              <div class="field">
                <label for="file">Image</label>
                <FileUpload
                  mode="basic"
                  id="file_attachment"
                  ref="file_attachment"
                  @change="handleFileUpload"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />

                <img
                  :src="product.imageurl"
                  :alt="product.imageurl"
                  class="shadow-2"
                  width="100"
                  v-if="product.imageurl"
                />
              </div>
              
              

              <div class="field">
                <label for="status">Status</label>

                <Dropdown
                  id="state"
                  v-model="selectstatus2"
                  :options="dropdownItems2"
                  :value="dropdownItems2.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !selectstatus2 }"
                  required="true"
                ></Dropdown>
              </div>


            </div>
            <div class="col-12 md:col-6">
              <Button
                v-if="product.id"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Addscheme"
              />
              <Button
                v-else
                label="ADD"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Addscheme"
              />

            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="productDialog = false;"
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deletescheme"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
      isLoading:false,
			isLoadingModel:false,
			loading: false,
			fullPage:true,
      file_attachment: "",
      dropdownItems: [
        { name: "Motivational", value: "Motivational" },
        { name: "Interview", value: "Interview" },
      ],
      dropdownItems2: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
				{ name: "Deleted", value: "Deleted" }
      ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus2: { name: "Active", value: "Active" },
      // selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];
      this.product.imageurl="";
      //alert(this.$refs.file_attachment.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var data = {
        count: true,
      };
      this.loading = true;
      var promise = apis.schemelist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var data = {
        Limit: this.limit,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.schemelist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.selectstatus2={name:"",value:""}
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
  async Addscheme() {
this.submitted = true;
    if(!this.product.name)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Name",
            life: 3000,
          });
          return false;
    }
    if(!this.product.description)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Description",
            life: 3000,
          });
          return false;
    }
    if(!this.product.weblink)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter weblink",
            life: 3000,
          });
          return false;
    }
    if (!this.product.id) {
    if(!this.file_attachment.name)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select image",
            life: 3000,
          });
          return false;
    }
    }
    if(!this.selectstatus2.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Status",
            life: 3000,
          });
          return false;
    }
      
       //edit
      if (this.product.id) {

        var file_name = "";
        var d = new Date();
        var file_name3="";
        if (this.file_attachment.type == undefined) {
          file_name3 = this.product.image;
        } else {
          file_name = this.file_attachment.name;

          file_name3 =
                  d.getTime() +
                  "_" +
                  d.getMilliseconds() +
                  "_" +
                  file_name;

        }

        var data = {
          Id: this.product.id,
          name: this.product.name,
          description: this.product.description,
          weblink: this.product.weblink,
          status: this.selectstatus2.value,
          imageurl:file_name3,
          updated_by: localStorage.getItem("id")
        };
        this.isLoadingModel = true;
        var promise = apis.editschememaster(data);
        promise
          .then((responseapi) => {
            if (responseapi.status == 200) {
            if (this.file_attachment.name) {
                // axios
                //   .post(
                //     "https://c9gqxigqgb.execute-api.ap-south-1.amazonaws.com/production/sgbfileupload/v1",

                //     {
                //       filePath:
                //         "schememaster/" + this.file_attachment.name,
                //       contentType: this.file_attachment.type,
                //     }
                //   )


                  var file_data={
                  filePath:"schememaster/" + file_name3,
                  contentType: this.file_attachment.type,

                }
                var promise5 = apis.uploadFile(file_data);
                promise5
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachment, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachment.type,
                        },
                      })
                      .then((response) => {
                        this.$swal(responseapi.data);

                        this.productDialog = false;

                        this.isLoadingModel = false;
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        // $("#exampleModal").modal("hide");
                        //this.text = response.data.message;
                        //$("#error_modal").modal("show");
                        this.$swal("error to upload file");

                        //alert();
                      });
                  });
              } else {
                this.$swal(responseapi.data);
                // this.hideDialog1();
                this.get_list();
                this.productDialog = false;

                this.isLoadingModel = false;
              }
            } else {
              this.$swal(responseapi.data);
              // this.hideDialog1();
              this.productDialog = false;

              this.isLoadingModel = false;
            }
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
             this.get_list();
             this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {

        var file_name2 = "";
        var d2 = new Date();
        if (this.file_attachment.type)
        {
            file_name2 =
                  d2.getTime() +
                  "_" +
                  d2.getMilliseconds() +
                  "_" +
                  this.file_attachment.name;
        }
        else
        {
            file_name2 = "";
        }


        var data1 = 
        {
          name: this.product.name,
        description: this.product.description,
        weblink: this.product.weblink,
        
        status: this.selectstatus2.value,
        imageurl:  file_name2,
        createD_BY: localStorage.getItem("id")
        };
        this.isLoadingModel = true;
        var promises = apis.addschememaster(data1);
        promises
          .then((responseapi) => {
            
            if (responseapi.status == 200) {
              if (this.file_attachment.name) {
                // axios
                //   .post(
                //     "https://c9gqxigqgb.execute-api.ap-south-1.amazonaws.com/production/sgbfileupload/v1",

                //     {
                //       filePath:
                //         "mahajyoti/schememaster/" + this.file_attachment.name,
                //       contentType: this.file_attachment.type,
                //     }
                //   )

                var file_data={
                  filePath:"schememaster/" +  file_name2,
                  contentType: this.file_attachment.type,

                }
                var promise2 = apis.uploadFile(file_data);
                promise2
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachment, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachment.type,
                        },
                      })
                      .then((response) => {
                        this.$swal(responseapi.data);

                        this.get_list();

                        this.productDialog = false;

                        this.isLoadingModel = false;
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        // $("#exampleModal").modal("hide");
                        //this.text = response.data.message;
                        //$("#error_modal").modal("show");
                        this.$swal("error to upload file");

                        //alert();
                      });
                  });
              } else {
                this.$swal(responseapi.data);
                // this.hideDialog1();
                this.productDialog = false;
                this.get_list();
                this.isLoadingModel = false;
              }
            } else {
              this.$swal(responseapi.data);
              // this.hideDialog1();
              this.productDialog = false;

              this.isLoadingModel = false;
            }
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
       this.selectstatus={name:"",value:""}
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
      
      // this.selectstatus.value = this.product.type;
      // this.selectstatus.name = this.product.type;

      this.selectstatus2.value = this.product.status;
      this.selectstatus2.name = this.product.status;

      this.file_attachment = this.product.imageurl;
      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deletescheme() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deleteschememaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
